exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carriers-js": () => import("./../../../src/pages/carriers.js" /* webpackChunkName: "component---src-pages-carriers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dispatch-quote-js": () => import("./../../../src/pages/dispatch-quote.js" /* webpackChunkName: "component---src-pages-dispatch-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-dispatch-services-js": () => import("./../../../src/pages/services/dispatch-services.js" /* webpackChunkName: "component---src-pages-services-dispatch-services-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-shipper-services-js": () => import("./../../../src/pages/services/shipper-services.js" /* webpackChunkName: "component---src-pages-services-shipper-services-js" */),
  "component---src-pages-shippers-quote-js": () => import("./../../../src/pages/shippers-quote.js" /* webpackChunkName: "component---src-pages-shippers-quote-js" */)
}

